import './public-path';
import Vue from 'vue';
import vuetify from './plugins/vuetify';
import { i18n } from './plugins/intl';
import './plugins';
import IntersectionObserver from 'intersection-observer';
import VueObserveVisibility from 'vue-observe-visibility';
import VueCookies from 'vue-cookies';
import vOdometer from '@/components/common/form/Odometer.vue';
import customButton from '@/components/common/form/CustomButton.vue';
import DottedSpacer from '@/common/DottedSpacer.vue';

import App from './App.vue';
import router from './router';
import store from './store';
import '@/filters';

import '@mdi/font/css/materialdesignicons.css';
import LoadingInterceptor from '@/common/LoadingInterceptor';
import smoothscroll from 'smoothscroll-polyfill';
import _ from 'lodash';

import VueGtm from 'vue-gtm';
import VueGtag from 'vue-gtag';
import config from './config';

Vue.config.productionTip = false;

Vue.use(IntersectionObserver);
Vue.use(VueObserveVisibility);
Vue.use(VueCookies);

Vue.$cookies.config('30d', '', '', true, 'None');

if ((config.ga as any)?.tracking_id) {
  Vue.use(VueGtag, {
    config: {
      id: (config.ga as any)?.tracking_id,
      pararms: {
        send_page_view: false
      }
    }
  });
}

if ((config.gtm as any)?.tracking_id) {
  Vue.use(VueGtm, {
    config: {
      id: (config.gtm as any)?.tracking_id,
      defer: false,
      enabled: true,
      debug: false,
      loadScript: true,
      vueRouter: router,
    }
  });
}

Vue.component('v-odometer', vOdometer);
Vue.component('c-btn', customButton);
Vue.component('c-dotted-spacer', DottedSpacer);

LoadingInterceptor();
smoothscroll.polyfill();

const app = new Vue({
  vuetify,
  i18n,
  router,
  store,
  render: (h) => h(App),
});

Vue.mixin({
  computed: {
	  $colors() {
	    return _.get(store.state.cms, 'theme.colors', {});
    },
    $isIE() {
	    return /Trident\/|MSIE /.test(window.navigator.userAgent);
    }
  }
});

Vue.prototype.$t = (key, args) => {
  return i18n.te(key) ? i18n.t(key, args) : '';
};

// Polyfill IE
if (!Element.prototype.matches) {
  // @ts-ignore: IE polyfill for matches
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

app.$mount('#app');
